import {
  BaseStepIdEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';
import { CompanyDetails } from '../app/components/company-details-v2';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_details',
    type: StepTypeEnum.custom_component,
    component: CompanyDetails,
  },
  {
    id: 'origin_funds',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'legit_origin_funds',
          type: FieldTypeEnum.checkbox,
          isRequired: true,
          isEnabled: true,
          options: ['true'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'origin_funds',
          type: FieldTypeEnum.select,
          isRequired: true,
          isEnabled: true,
          options: ['company_revenue', 'shareholder_contribution'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
];
