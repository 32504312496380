import { useMemo } from 'react';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GroupController,
  Select,
  SelectOption,
  Radio,
  Checkbox,
} from 'frontend-components';
import { useStepId, useStore, useSubmitCompanyForm } from 'frontend-common';
import { elfCodeMapping } from '../../assets/elf_code_mapping';
import { InferType } from 'yup';

const trust_legal_form_enum = [
  'P24O | Business Trust',
  '4JCS | Non-deposit Trust Company',
  'SHCN | Statutory Trust',
  '4FSX | Statutory Trust',
  'MIPY | Non-deposit Trust Company',
  'SQOE | Statutory Trust',
  '72CA | Non-deposit Trust Company',
  'ALW3 | Non-deposit Trust Company',
  'YTY3 | Non-deposit Trust Company',
  'EW9A | Business Trust',
  'M1FY | Non-deposit Trust Company',
  'T7D4 | Non-deposit Trust Company',
  'WC8Q | Business Trust',
  '85YQ | Statutory Trust (LTD)',
  'OFGO | Business Trust (LTD)',
  'EO9A | Foreign Real Estate Investment Trust',
  'NV9X | Domestic Real Estate Investment Trust',
  'KU3O | Non-deposit Trust Company',
  'Z73Z | Voluntary Association and Business Trusts',
  '8OK2 | Business Trusts',
  'VSDJ | Non-deposit Trust Company',
  '1ADA | Family Trust Company',
  'QSHG | Non-deposit Trust Company',
  '30TX | Trust Company',
  'QB0A | Business Trust',
  'SUEQ | Business Trust (BT)',
  'S97G | Investment Trust',
  'YVSB | Non-deposit Trust Company',
  'JF6D | Nonprofit Community Trust (inc)',
  'NJA5 | Real Estate Investment Trusts (REIT)',
  'JBQI | Non-deposit Trust Company',
  'T2DF | Business Trust (trust)',
  'Z9C5 | Non-deposit Trust Company',
  '23Q4 | Non-deposit Trust Company',
  '6TGS | Non-deposit Trust Company',
  'YY5A | Non-deposit Trust Company',
  '7AJT | Non-deposit Trust Company',
  'B12O | Business Trust',
  'BOEX | Business Trust',
  'OF3Q | Non-deposit Trust Company',
  'O9MN | Business Trust',
  'R27J | Non-deposit Trust Company',
  'B5YE | Non-deposit Trust Company',
  'ZXZ7 | Common Law Trust',
  'RDQZ | Statutory Trust',
];
const trade_registry_location = 'trade_registry_location';
const is_company_accredited = 'is_company_accredited';
const is_us_tax_exempt = 'is_us_tax_exempt';
const entity_accredited_status = 'entity_accredited_status';

const trust_accredited_status_options = [
  'trust_assets',
  'trust_UBOS_accredited_investors',
];
const entity_accredited_status_options = [
  'assets',
  'UBOs_qualified_purchasers',
  'UBOs_accredited_investors',
  'investment_adviser',
];

const full_accredited_status_options = [
  'trust_assets',
  'trust_UBOS_accredited_investors',
  'assets',
  'UBOs_qualified_purchasers',
  'UBOs_accredited_investors',
  'investment_adviser',
];

const validationSchema = Yup.object({
  subdivision_country: Yup.string().optional(),
  legal_form: Yup.string().required().label('Legal form'),
  [trade_registry_location]: Yup.string()
    .required()
    .label('Trade registry location'),

  [is_us_tax_exempt]: Yup.string()
    .oneOf(['true', 'false'])
    .when('$company_country', {
      is: (val: string) => val === 'US',
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),

  [is_company_accredited]: Yup.string()
    .oneOf(['true', 'false'])
    .when('$company_country', {
      is: (val: string) => val === 'US',
      then: (schema) =>
        schema
          .required()
          .test(
            'is-accredited',
            'Securities offered on Roundtable have not been registered under the US Securities ACT. As such, US investors must be accredited investors.',
            (value) => value === 'true',
          ),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
  [entity_accredited_status]: Yup.array(
    Yup.mixed().oneOf(full_accredited_status_options),
  ).when(is_company_accredited, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.min(1).label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

type CompanyDetailFormValues = InferType<typeof validationSchema>;

export const CompanyDetails = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCompanyForm } = useSubmitCompanyForm();
  const { company, metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      subdivision_country: metadata['subdivision_country']?.toString() || '',
      legal_form: company?.legal_form || '',
      [trade_registry_location]:
        metadata?.[trade_registry_location]?.toString() || '',
      [is_us_tax_exempt]: metadata?.[is_us_tax_exempt]?.toString() || '',
      [is_company_accredited]:
        metadata?.[is_company_accredited]?.toString() || '',
      // trust_accredited_status: (metadata['trust_accredited_status'] ||
      //   []) as string[],
      [entity_accredited_status]: (metadata?.[entity_accredited_status] ||
        []) as string[],
    } as CompanyDetailFormValues;
  }, [company, metadata]);

  const methods = useForm<CompanyDetailFormValues>({
    //const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(
      validationSchema,
    ) as Resolver<CompanyDetailFormValues>,
    context: { company_country: company?.country },
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    watch,
    formState: { isValid, isSubmitting },
  } = methods;
  const onSubmit: SubmitHandler<CompanyDetailFormValues> = async (formData) => {
    // const onSubmit: SubmitHandler<any> = async (formData) => {
    if (company) {
      submitCompanyForm({
        companyData: {
          ...company,
          legal_form: formData.legal_form,
        },
        caseMetadata: {
          ...metadata,
          trade_registry_location: formData.trade_registry_location,
          subdivision_country: formData.subdivision_country ?? null,
          is_us_tax_exempt: formData.is_us_tax_exempt ?? null,
          is_company_accredited: formData.is_company_accredited ?? null,
          entity_accredited_status:
            (formData.entity_accredited_status as string[]) ?? null,
        },
      });
    }
  };

  const hasSubdivision = () => {
    return company?.country
      ? elfCodeMapping[company.country].find((s) => s.subdivision_code !== null)
      : false;
  };

  const subdivisionOptions = () => {
    return elfCodeMapping[company?.country as string]
      .filter(
        (s) => s.subdivision_code !== null && s.subdivision_label !== null,
      )
      .map((s) => {
        return {
          value: s.subdivision_code as string,
          label: s.subdivision_label as string,
        };
      });
  };

  const legalFormOptions = (subdivision: string | null) => {
    const subdivisions = elfCodeMapping[company?.country as string].filter(
      (s) => s.subdivision_code === subdivision || s.subdivision_code === null,
    );
    const options: SelectOption[] = [];
    subdivisions.forEach((s) => {
      s.legal_forms.forEach((lf) => {
        options.push({
          value: `${lf.code} | ${lf.label}`,
          label: lf.label,
        });
      });
    });

    // Add other option
    options.push({
      value: '8888 | Other',
      label: 'Other',
    });
    // Add not application option
    options.push({
      value: '9999 | Not applicable',
      label: 'Not applicable',
    });

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  };

  const subdivisionCountry = watch('subdivision_country');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        {hasSubdivision() && (
          <GroupController
            name="subdivision_country"
            label="Jurisdiction"
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name="subdivision_country"
                onChange={(value: string) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue('subdivision_country', value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue('legal_form', '');
                }}
                options={subdivisionOptions()}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                defaultValue={f.value}
                isMulti={false}
                isTranslatableOptions={false}
              />
            )}
          />
        )}

        <GroupController
          name="legal_form"
          label={t(`steps.${stepId}.legal_form.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name="legal_form"
              onChange={(value: string) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                resetField(trade_registry_location);
                resetField(is_us_tax_exempt);
                resetField(is_company_accredited);
                resetField(entity_accredited_status);
                setValue('legal_form', value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={legalFormOptions(subdivisionCountry ?? null)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              defaultValue={f.value ?? ''}
              isMulti={false}
              isTranslatableOptions={false}
            />
          )}
        />

        <GroupController
          name={trade_registry_location}
          label={t(`steps.${stepId}.${trade_registry_location}.label`)}
          helper={t(`steps.${stepId}.${trade_registry_location}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        {company?.country === 'US' && (
          <>
            <GroupController
              name={is_us_tax_exempt}
              label={t(`steps.${stepId}.${is_us_tax_exempt}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={is_us_tax_exempt}
                  onChange={(value: 'true' | 'false' | undefined) => {
                    setValue(is_us_tax_exempt, value ?? 'false', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  value={f.value ?? ''}
                />
              )}
            />
            <GroupController
              name={is_company_accredited}
              label={t(`steps.${stepId}.${is_company_accredited}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={is_company_accredited}
                  onChange={(value: 'true' | 'false' | undefined) => {
                    resetField(entity_accredited_status);
                    setValue(is_company_accredited, value ?? 'false', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  value={f.value ?? ''}
                />
              )}
            />

            {watch(is_company_accredited) === 'true' &&
              trust_legal_form_enum.includes(watch('legal_form')) && (
                <GroupController
                  name="entity_accredited_status"
                  label={t(`steps.${stepId}.${entity_accredited_status}.label`)}
                  isRequired={false}
                  control={control}
                  render={(f) => (
                    <Checkbox
                      stepId={stepId}
                      name="entity_accredited_status"
                      onChange={(value: string[]) => {
                        setValue(entity_accredited_status, value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      options={trust_accredited_status_options}
                      defaultValue={(f.value as string[]) ?? []}
                    />
                  )}
                />
              )}

            {watch(is_company_accredited) === 'true' &&
              !trust_legal_form_enum.includes(watch('legal_form')) && (
                <GroupController
                  name="entity_accredited_status"
                  label={t(`steps.${stepId}.${entity_accredited_status}.label`)}
                  isRequired={false}
                  control={control}
                  render={(f) => (
                    <Checkbox
                      stepId={stepId}
                      name="entity_accredited_status"
                      onChange={(value: string[]) => {
                        setValue(entity_accredited_status, value, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      options={entity_accredited_status_options}
                      defaultValue={(f.value as string[]) ?? []}
                    />
                  )}
                />
              )}
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
