export const countries = [
  {
    code: 'AD',
    iso3: 'AND',
    name: 'Andorra',
    localName: 'Andorra',
    continent: 'Europe',
    emoji: '🇦🇩',
    emojiCode: '1F1E6 1F1E9',
  },
  {
    code: 'AR',
    iso3: 'ARG',
    name: 'Argentina',
    localName: 'Argentina',
    continent: 'South America',
    emoji: '🇦🇷',
    emojiCode: '1F1E6 1F1F7',
  },
  {
    code: 'AU',
    iso3: 'AUS',
    name: 'Australia',
    localName: 'Australia',
    continent: 'Oceania',
    emoji: '🇦🇺',
    emojiCode: '1F1E6 1F1FA',
  },
  {
    code: 'AT',
    iso3: 'AUT',
    name: 'Austria',
    localName: 'Österreich',
    continent: 'Europe',
    emoji: '🇦🇹',
    emojiCode: '1F1E6 1F1F9',
  },
  {
    code: 'BY',
    iso3: 'BLR',
    name: 'Belarus',
    localName: 'Белару́сь',
    continent: 'Europe',
    emoji: '🇧🇾',
    emojiCode: '1F1E7 1F1FE',
  },
  {
    code: 'BE',
    iso3: 'BEL',
    name: 'Belgium',
    localName: 'Belgien',
    continent: 'Europe',
    emoji: '🇧🇪',
    emojiCode: '1F1E7 1F1EA',
  },
  {
    code: 'BG',
    iso3: 'BGR',
    name: 'Bulgaria',
    localName: 'България',
    continent: 'Europe',
    emoji: '🇧🇬',
    emojiCode: '1F1E7 1F1EC',
  },
  {
    code: 'BR',
    iso3: 'BRA',
    name: 'Brazil',
    localName: 'Brasil',
    continent: 'South America',
    emoji: '🇧🇷',
    emojiCode: '1F1E7 1F1F7',
  },
  {
    code: 'KH',
    iso3: 'KHM',
    name: 'Cambodia',
    localName: 'Kâmpŭchéa',
    continent: 'Asia',
    emoji: '🇰🇭',
    emojiCode: '1F1F0 1F1ED',
  },
  {
    code: 'CA',
    iso3: 'CAN',
    name: 'Canada',
    localName: 'Canada',
    continent: 'North America',
    emoji: '🇨🇦',
    emojiCode: '1F1E8 1F1E6',
  },
  {
    code: 'CN',
    iso3: 'CHN',
    name: 'China',
    localName: '中国',
    continent: 'Asia',
    emoji: '🇨🇳',
    emojiCode: '1F1E8 1F1F3',
  },
  {
    code: 'KY',
    iso3: 'CYM',
    name: 'Cayman Islands',
    localName: 'Cayman Islands',
    continent: 'North America',
    emoji: '🇰🇾',
    emojiCode: '1F1F0 1F1FE',
  },
  {
    code: 'HR',
    iso3: 'HRV',
    name: 'Croatia',
    localName: 'Hrvatska',
    continent: 'Europe',
    emoji: '🇭🇷',
    emojiCode: '1F1ED 1F1F7',
  },
  {
    code: 'CY',
    iso3: 'CYP',
    name: 'Cyprus',
    localName: 'Κύπρος',
    continent: 'Europe',
    emoji: '🇨🇾',
    emojiCode: '1F1E8 1F1FE',
  },
  {
    code: 'CZ',
    iso3: 'CZE',
    name: 'Czechia',
    localName: 'Česko',
    continent: 'Europe',
    emoji: '🇨🇿',
    emojiCode: '1F1E8 1F1FF',
  },
  {
    code: 'DK',
    iso3: 'DNK',
    name: 'Denmark',
    localName: 'Danmark',
    continent: 'Europe',
    emoji: '🇩🇰',
    emojiCode: '1F1E9 1F1F0',
  },
  {
    code: 'EE',
    iso3: 'EST',
    name: 'Estonia',
    localName: 'Eesti',
    continent: 'Europe',
    emoji: '🇪🇪',
    emojiCode: '1F1EA 1F1EA',
  },
  {
    code: 'FI',
    iso3: 'FIN',
    name: 'Finland',
    localName: 'Suomi',
    continent: 'Europe',
    emoji: '🇫🇮',
    emojiCode: '1F1EB 1F1EE',
  },
  {
    code: 'FR',
    iso3: 'FRA',
    name: 'France',
    localName: 'France',
    continent: 'Europe',
    emoji: '🇫🇷',
    emojiCode: '1F1EB 1F1F7',
  },
  {
    code: 'DE',
    iso3: 'DEU',
    name: 'Germany',
    localName: 'Deutschland',
    continent: 'Europe',
    emoji: '🇩🇪',
    emojiCode: '1F1E9 1F1EA',
  },
  {
    code: 'GI',
    iso3: 'GIB',
    name: 'Gibraltar',
    localName: 'Gibraltar',
    continent: 'Europe',
    emoji: '🇬🇮',
    emojiCode: '1F1EC 1F1EE',
  },
  {
    code: 'GR',
    iso3: 'GRC',
    name: 'Greece',
    localName: 'Ελλάδα',
    continent: 'Europe',
    emoji: '🇬🇷',
    emojiCode: '1F1EC 1F1F7',
  },
  {
    code: 'GL',
    iso3: 'GRL',
    name: 'Greenland',
    localName: 'Kalaallit Nunaat',
    continent: 'North America',
    emoji: '🇬🇱',
    emojiCode: '1F1EC 1F1F1',
  },
  {
    code: 'GG',
    iso3: 'GGY',
    name: 'Guernsey',
    localName: 'Guernsey',
    continent: 'Europe',
    emoji: '🇬🇬',
    emojiCode: '1F1EC 1F1EC',
  },
  {
    code: 'HK',
    iso3: 'HKG',
    name: 'Hong Kong',
    localName: 'Hong Kong',
    continent: 'Asia',
    emoji: '🇭🇰',
    emojiCode: '1F1ED 1F1F0',
  },
  {
    code: 'HU',
    iso3: 'HUN',
    name: 'Hungary',
    localName: 'Magyarország',
    continent: 'Europe',
    emoji: '🇭🇺',
    emojiCode: '1F1ED 1F1FA',
  },
  {
    code: 'IN',
    iso3: 'IND',
    name: 'India',
    localName: 'India',
    continent: 'Asia',
    emoji: '🇮🇳',
    emojiCode: '1F1EE 1F1F3',
  },
  {
    code: 'ID',
    iso3: 'IDN',
    name: 'Indonesia',
    localName: 'Indonesia',
    continent: 'Asia',
    emoji: '🇮🇩',
    emojiCode: '1F1EE 1F1E9',
  },
  {
    code: 'IE',
    iso3: 'IRL',
    name: 'Ireland',
    localName: 'Ireland',
    continent: 'Europe',
    emoji: '🇮🇪',
    emojiCode: '1F1EE 1F1EA',
  },
  {
    code: 'IL',
    iso3: 'ISR',
    name: 'Israel',
    localName: 'إسرائيل',
    continent: 'Asia',
    emoji: '🇮🇱',
    emojiCode: '1F1EE 1F1F1',
  },
  {
    code: 'IT',
    iso3: 'ITA',
    name: 'Italy',
    localName: 'Italia',
    continent: 'Europe',
    emoji: '🇮🇹',
    emojiCode: '1F1EE 1F1F9',
  },
  {
    code: 'JP',
    iso3: 'JPN',
    name: 'Japan',
    localName: '日本',
    continent: 'Asia',
    emoji: '🇯🇵',
    emojiCode: '1F1EF 1F1F5',
  },
  {
    code: 'JE',
    iso3: 'JEY',
    name: 'Jersey',
    localName: 'Jersey',
    continent: 'Europe',
    emoji: '🇯🇪',
    emojiCode: '1F1EF 1F1EA',
  },
  {
    code: 'KG',
    iso3: 'KGZ',
    name: 'Kyrgyzstan',
    localName: 'Кыргызстан',
    continent: 'Asia',
    emoji: '🇰🇬',
    emojiCode: '1F1F0 1F1EC',
  },
  {
    code: 'LI',
    iso3: 'LIE',
    name: 'Liechtenstein',
    localName: 'Liechtenstein',
    continent: 'Europe',
    emoji: '🇱🇮',
    emojiCode: '1F1F1 1F1EE',
  },
  {
    code: 'LV',
    iso3: 'LVA',
    name: 'Latvia',
    localName: 'Latvija',
    continent: 'Europe',
    emoji: '🇱🇻',
    emojiCode: '1F1F1 1F1FB',
  },
  {
    code: 'LT',
    iso3: 'LTU',
    name: 'Lithuania',
    localName: 'Lietuva',
    continent: 'Europe',
    emoji: '🇱🇹',
    emojiCode: '1F1F1 1F1F9',
  },
  {
    code: 'LU',
    iso3: 'LUX',
    name: 'Luxembourg',
    localName: 'Luxemburg',
    continent: 'Europe',
    emoji: '🇱🇺',
    emojiCode: '1F1F1 1F1FA',
  },
  {
    code: 'MA',
    iso3: 'MAR',
    name: 'Morocco',
    localName: 'المغرب',
    continent: 'Africa',
    emoji: '🇲🇦',
    emojiCode: '1F1F2 1F1E6',
  },
  {
    code: 'MH',
    iso3: 'MHL',
    name: 'Marshall Islands',
    localName: 'Marshall Islands',
    continent: 'Oceania',
    emoji: '🇲🇭',
    emojiCode: '1F1F2 1F1ED',
  },
  {
    code: 'MU',
    iso3: 'MUS',
    name: 'Mauritius',
    localName: 'Mauritius',
    continent: 'Africa',
    emoji: '🇲🇺',
    emojiCode: '1F1F2 1F1FA',
  },
  {
    code: 'MK',
    iso3: 'MKD',
    name: 'North Macedonia',
    localName: 'Македонија',
    continent: 'Europe',
    emoji: '🇲🇰',
    emojiCode: '1F1F2 1F1F0',
  },
  {
    code: 'MY',
    iso3: 'MYS',
    name: 'Malaysia',
    localName: 'Malaysia',
    continent: 'Asia',
    emoji: '🇲🇾',
    emojiCode: '1F1F2 1F1FE',
  },
  {
    code: 'MT',
    iso3: 'MLT',
    name: 'Malta',
    localName: 'Malta',
    continent: 'Europe',
    emoji: '🇲🇹',
    emojiCode: '1F1F2 1F1F9',
  },
  {
    code: 'MC',
    iso3: 'MCO',
    name: 'Monaco',
    localName: 'Monaco',
    continent: 'Europe',
    emoji: '🇲🇨',
    emojiCode: '1F1F2 1F1E8',
  },
  {
    code: 'ME',
    iso3: 'MNE',
    name: 'Montenegro',
    localName: 'Црна Гора',
    continent: 'Europe',
    emoji: '🇲🇪',
    emojiCode: '1F1F2 1F1EA',
  },
  {
    code: 'NL',
    iso3: 'NLD',
    name: 'Netherlands',
    localName: 'Nederland',
    continent: 'Europe',
    emoji: '🇳🇱',
    emojiCode: '1F1F3 1F1F1',
  },
  {
    code: 'NZ',
    iso3: 'NZL',
    name: 'New Zealand',
    localName: 'New Zealand',
    continent: 'Oceania',
    emoji: '🇳🇿',
    emojiCode: '1F1F3 1F1FF',
  },
  {
    code: 'NO',
    iso3: 'NOR',
    name: 'Norway',
    localName: 'Noreg',
    continent: 'Europe',
    emoji: '🇳🇴',
    emojiCode: '1F1F3 1F1F4',
  },
  {
    code: 'PL',
    iso3: 'POL',
    name: 'Poland',
    localName: 'Polska',
    continent: 'Europe',
    emoji: '🇵🇱',
    emojiCode: '1F1F5 1F1F1',
  },
  {
    code: 'PT',
    iso3: 'PRT',
    name: 'Portugal',
    localName: 'Portugal',
    continent: 'Europe',
    emoji: '🇵🇹',
    emojiCode: '1F1F5 1F1F9',
  },
  {
    code: 'RO',
    iso3: 'ROU',
    name: 'Romania',
    localName: 'România',
    continent: 'Europe',
    emoji: '🇷🇴',
    emojiCode: '1F1F7 1F1F4',
  },
  {
    code: 'RS',
    iso3: 'SRB',
    name: 'Serbia',
    localName: 'Србија',
    continent: 'Europe',
    emoji: '🇷🇸',
    emojiCode: '1F1F7 1F1F8',
  },
  {
    code: 'RU',
    iso3: 'RUS',
    name: 'Russia',
    localName: 'Россия',
    continent: 'Asia',
    emoji: '🇷🇺',
    emojiCode: '1F1F7 1F1FA',
  },
  {
    code: 'SG',
    iso3: 'SGP',
    name: 'Singapore',
    localName: '新加坡',
    continent: 'Asia',
    emoji: '🇸🇬',
    emojiCode: '1F1F8 1F1EC',
  },
  {
    code: 'SK',
    iso3: 'SVK',
    name: 'Slovakia',
    localName: 'Slovensko',
    continent: 'Europe',
    emoji: '🇸🇰',
    emojiCode: '1F1F8 1F1F0',
  },
  {
    code: 'SI',
    iso3: 'SVN',
    name: 'Slovenia',
    localName: 'Slovenija',
    continent: 'Europe',
    emoji: '🇸🇮',
    emojiCode: '1F1F8 1F1EE',
  },
  {
    code: 'KR',
    iso3: 'KOR',
    name: 'South Korea',
    localName: '한국',
    continent: 'Asia',
    emoji: '🇰🇷',
    emojiCode: '1F1F0 1F1F7',
  },
  {
    code: 'ES',
    iso3: 'ESP',
    name: 'Spain',
    localName: 'España',
    continent: 'Europe',
    emoji: '🇪🇸',
    emojiCode: '1F1EA 1F1F8',
  },
  {
    code: 'SE',
    iso3: 'SWE',
    name: 'Sweden',
    localName: 'Sverige',
    continent: 'Europe',
    emoji: '🇸🇪',
    emojiCode: '1F1F8 1F1EA',
  },
  {
    code: 'CH',
    iso3: 'CHE',
    name: 'Switzerland',
    localName: 'Suisse',
    continent: 'Europe',
    emoji: '🇨🇭',
    emojiCode: '1F1E8 1F1ED',
  },
  {
    code: 'TW',
    iso3: 'TWN',
    name: 'Taiwan',
    localName: '台灣',
    continent: 'Asia',
    emoji: '🇹🇼',
    emojiCode: '1F1F9 1F1FC',
  },
  {
    code: 'TH',
    iso3: 'THA',
    name: 'Thailand',
    localName: 'ประเทศไทย',
    continent: 'Asia',
    emoji: '🇹🇭',
    emojiCode: '1F1F9 1F1ED',
  },
  {
    code: 'UA',
    iso3: 'UKR',
    name: 'Ukraine',
    localName: 'Україна',
    continent: 'Europe',
    emoji: '🇺🇦',
    emojiCode: '1F1FA 1F1E6',
  },
  {
    code: 'AE',
    iso3: 'ARE',
    name: 'United Arab Emirates',
    localName: 'دولة الإمارات العربية المتحدة',
    continent: 'Asia',
    emoji: '🇦🇪',
    emojiCode: '1F1E6 1F1EA',
  },
  {
    code: 'GB',
    iso3: 'GBR',
    name: 'United Kingdom',
    localName: 'United Kingdom',
    continent: 'Europe',
    emoji: '🇬🇧',
    emojiCode: '1F1EC 1F1E7',
  },
  {
    code: 'US',
    iso3: 'USA',
    name: 'United States',
    localName: 'United States',
    continent: 'North America',
    emoji: '🇺🇸',
    emojiCode: '1F1FA 1F1F8',
  },
];
